<template>  
  <div>
    <router-view  />
  </div>  
</template>

<script>


export default {
  name: 'App',
  watch: {
    $route() {      
        console.log('app watch route')
      /*   window.dataLayer.push({
          'event': 'eventNavigation',
          'category': '',
          'action': '',
          'label': ''
        });  
      } */
    }
  },
  created()  {
    //get the promo status - before, live, after    
      this.get('info/status',{})
        .then((resp) => {
          this.$store.state.promoStatus = resp.data.promo_status                    
          this.$store.state.loginShow = resp.data.promo_login_status     
        }).catch((err) => {
            console.log(err)
        })

        this.$store.dispatch("getShops");
        this.$store.dispatch("getFileUrl")
  }
}
</script>